import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';

const ACCEPT_TOS_ENDPOINT = '/v2/companyManagement/companies/:company/acceptTos';

@Injectable({ providedIn: 'root' })
export class CompanyMetaService {
    constructor(
        private readonly http: HttpClient,
        private auth: AuthService
    ) {}

    setTosComplete(email: string): Promise<unknown> {
        const url = ACCEPT_TOS_ENDPOINT.replace(':company', this.auth.company.id.toString());
        const payload = {
            data: {
                type: 'companyMeta',
                attributes: {
                    tos_accepted_email: email,
                },
            },
        };
        return this.http.post(url, payload).toPromise();
    }
}
